<script lang="ts">
export default {
    name: 'DonnéesPersonnelles',
    setup() {
        useHead({
            title: 'Données personnelles',
        })
    },
    data() {
        return {}
    },
}
</script>

<template>
    <InfoCard text="À faire" level="warning" />
</template>

<!-- suppress JSUnresolvedReference -->
